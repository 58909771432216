<template>
    <router-view />
</template>
<style lang="scss">
    * {
        margin: 0;
        padding: 0;
    }
    #app {
        width: 100%;
        height: 100%;
    }
</style>
