<template>
    <div>
        <router-link to="/live"> 直播</router-link>
        <router-link to="/playback"> 回放</router-link>
        <router-link to="/testIframe"> 测试iframe</router-link>
        <router-link to="/testVideo"> 测试video</router-link>
    </div>
</template>
<script lang="ts" setup></script>
<style scoped lang="scss"></style>
