import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'

import AccessView from '../views/AccessView'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'access',
        component: AccessView,
    },
    // 直播后台
    {
        path: '/admin',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "Admin" */ '@/views/Admin'),
        children: [
            // 创建直播间
            {
                path: '/admin/creatLiveRoom',
                name: 'CreatLiveRoom',
                component: () =>
                    import(
                        /* webpackChunkName: "Admin.CreatLiveRoom" */ '@/views/Admin/ChildPage/CreatLiveRoom'
                    ),
            },
            // 直播间设置
            {
                path: '/admin/liveRoomSetup',
                name: 'LiveRoomSetup',
                component: () =>
                    import(
                        /* webpackChunkName: "Admin.LiveRoomSetup" */ '@/views/Admin/ChildPage/LiveRoomSetup'
                    ),
            },
        ],
    },
    // 观众端直播列表
    {
        path: '/liveList',
        name: 'liveList',
        component: () => import(/* webpackChunkName: "liveList" */ '@/views/LiveList'),
    },
    // 观众端回放列表
    {
        path: '/livePlaybackList',
        name: 'livePlaybackList',
        component: () =>
            import(/* webpackChunkName: "livePlaybackList" */ '@/views/LivePlaybackList'),
    },
    // 直播观看端
    {
        path: '/live',
        name: 'Live',
        component: () => import(/* webpackChunkName: "LiveIfname" */ '@/components/LiveIfname'),
    },
    // 直播讲师端
    {
        path: '/teacher',
        name: 'Teacher',
        component: () => import(/* webpackChunkName: "LiveIfname" */ '@/components/LiveIfname'),
    },
    // 直播助教端
    {
        path: '/assistant',
        name: 'Assistant',
        component: () => import(/* webpackChunkName: "LiveIfname" */ '@/components/LiveIfname'),
    },
    // 直播回放端
    {
        path: '/playback',
        name: 'Playback',
        component: () => import(/* webpackChunkName: "LiveIfname" */ '@/components/LiveIfname'),
    },
    // 直播主持人端
    {
        path: '/openhost',
        name: 'Openhost',
        component: () => import(/* webpackChunkName: "LiveIfname" */ '@/components/LiveIfname'),
    },
    // 页面无法刷新解决问题，参数由此路由中转
    {
        path: '/transferPath',
        name: 'TransferPath',
        component: () => import(/* webpackChunkName: "TransferPath" */ '@/components/transferPath'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
