<template>
    <div class="page-layout" ref="pageLayoutRef">
        <div class="button-content" v-if="isButton">
            <div class="flex">
                <div class="search-btn">
                    <el-button type="primary" ref="searchDrawer" @click="drawer = true">
                        搜索
                    </el-button>
                    <el-button type="primary" plain @click="$emit('formRefresh')"> 重置 </el-button>
                </div>
                <slot name="button"> </slot>
            </div>
        </div>
        <div class="table-content">
            <div ref="clientRect"> </div>
            <slot name="table" :tableHeight="tableHeight" v-if="tableHeight"> </slot>
        </div>
        <div class="pagination-content" ref="paginationContentRef">
            <slot name="pagination"> </slot>
        </div>
        <slot></slot>
        <el-drawer
            title="搜索条件"
            v-model="drawer"
            :append-to-body="false"
            :modal="false"
            :size="drawerSize"
            :direction="'ltr'"
            @open="open"
            @close="close"
            ref="drawer"
        >
            <div class="form-content">
                <div class="search-form-item">
                    <slot name="form"> </slot>
                </div>

                <div class="drawer-btn">
                    <el-button type="primary" @click="submit"> 搜索 </el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            drawerSize: {
                type: Number,
                default: 500,
            },
            isButton: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                drawer: false,
                tableHeight: 0,
            }
        },
        mounted() {
            this.setTableHeight()
            window.onresize = () => {
                this.setTableHeight()
            }
        },
        methods: {
            open() {
                // 失焦 防止 回车触发
                this.$refs['searchDrawer'] && this.$refs['searchDrawer'].$el.blur()
                this.drawer = true
                this.keyupEnter()
            },
            close() {
                this.drawer = false
                document.onkeydown = null
            },
            keyupEnter() {
                document.onkeydown = e => {
                    let _key = window.event.keyCode
                    if (_key === 13) {
                        this.submit(e)
                    }
                }
            },
            submit() {
                setTimeout(() => {
                    this.drawer = false
                    this.$emit('formSearch')
                }, 0)
            },
            // 计算表格高度
            setTableHeight(refName = 'clientRect') {
                const clientHeight =
                    document.documentElement.clientHeight || document.body.clientHeight //可视窗口 高
                const el = this.$refs[refName]
                if (el) {
                    const y = el.getBoundingClientRect().y
                    let height = clientHeight - y
                    this.tableHeight = height - 60
                }
                if (this.$refs.paginationContentRef && this.$refs.pageLayoutRef) {
                    if (
                        this.$refs.paginationContentRef.offsetWidth >
                        this.$refs.pageLayoutRef.clientWidth
                    ) {
                        this.tableHeight = this.tableHeight - 20
                    }
                }
                if (!this.isButton) {
                    this.tableHeight = this.tableHeight - 60
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .page-layout {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        .button-content {
            padding: 25px;
            border-bottom: 5px solid #eff2f3;
            .flex {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .search-btn {
                    flex: 1;
                    min-width: 260px;
                }
            }
        }
        .table-content {
            padding: 25px;
            padding-bottom: 50px;
            width: 100%;
            box-sizing: border-box;
        }
        .el-drawer__wrapper {
            position: absolute;
            .el-drawer__header {
                margin: 0;
                padding: 25px;
                border-bottom: 5px solid #eff2f3;
                span {
                    height: 34px;
                    color: #000000;
                    line-height: 34px;
                }
            }
        }
        .form-content {
            position: relative;
            padding-bottom: 40px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            .search-form-item {
                overflow-y: auto;
                padding: 20px 20px 0;

                // width: 100%;
                height: 100%;
            }

            // .label {
            //   margin-right: 5px;
            //   color: #303133;
            //   font-size: 14px;
            // }

            // .item-form {
            //   display: flex;
            //   align-items: center;
            //   height: 34px;
            //   margin-right: 20px;
            //   margin-bottom: 20px;
            // }
            .el-input {
                width: 200px;
            }
            .el-input__inner {
                height: 34px;
                line-height: 34px;
            }
            .el-input__icon {
                line-height: 34px;
            }
            .drawer-btn {
                position: absolute;
                bottom: 0;
                width: 100%;
                text-align: center;
            }
        }
        .button-content,
        .form-content {
            .el-button {
                padding: 10px 12px;
                min-width: 120px;
                height: 34px;
            }
        }
        .pagination-content {
            position: absolute;
            bottom: 10px;
            overflow: hidden;
            width: 100%;
        }
    }
</style>
