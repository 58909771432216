// 分页
<template>
    <div class="pagination">
        <el-pagination
            background
            :page-sizes="pageSizes"
            :page-size="pageSize"
            :current-page="currentPage"
            :disabled="disabled"
            :layout="layout"
            :total="total"
            :hide-on-single-page="hideOnSinglePage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: {
            layout: {
                type: String,
                default: 'total, sizes, prev, pager, next, jumper',
            },
            total: {
                type: [String, Number],
                default: 0,
            },
            currentPage: {
                type: [String, Number],
                default: 1,
            },
            //每页显示条目个数
            pageSize: {
                type: [String, Number],
                default: 0,
            },
            // 每页显示个数选择器的选项设置
            pageSizes: {
                type: Array,
                default: () => {
                    return [10, 20, 30, 40, 50, 100]
                },
            },
            // 只有一页时是否隐藏
            hideOnSinglePage: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            handleSizeChange(val) {
                this.$emit('handleSizeChange', val)
            },
            handleCurrentChange(val) {
                this.$emit('handleCurrentChange', val)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .pagination {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 32px;
        .el-pagination {
            padding: 0;
            color: #000000;
            li,
            .btn-prev,
            .btn-next {
                border: 1px solid #dcdfe6;
                background: #ffffff;
            }
            .el-pager {
                .number {
                    font-weight: 500;
                    border: 1px solid #dcdfe6;
                    background: #ffffff;
                }
            }
        }
    }
</style>
